import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        role: 'viewer',
        // role: 'admin',
    },
    reducers: {
        login: (state, action) => {
            state.role = action.payload.role;
        }, // 로그인시 세션이랑 같이 role 타입 저장하기
        logout : (state) => {state.role = 'viewer'},
    },
});

export const { login, logout} = authSlice.actions;
export default authSlice.reducer;