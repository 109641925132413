import '../../styles/layout.css';
import {Header, Nav} from '../../components/component';

const WebLayout = ({children}) =>{
   
return (
    <main className='flex'>
        <Nav />
        {/* 컴포넌트 */}
        <section className='xl:w-[125rem] resalescan-default-layout flex flex-col'>
            <article className='flex-1'>{children}</article>
        </section>   
    </main>
);
}

export default WebLayout;