import { lazy} from 'react';
// const HomeView = lazy(()=>'./HomeView.jsx');

// import HomeView  from './HomeView.jsx';
// import TestVie from './TestVie.jsx';

const HomeView = lazy(() => import('./HomeView.jsx'));
const ProductAnlyView = lazy(() => import('./ProductAnlyView.jsx'));
const ProductRankingView = lazy(() => import('./ProductRankingView.jsx'));
const UserManagementView = lazy(() => import('./UserManagementView.jsx'));
const UserInfoView = lazy(() => import('./UserInfoView.jsx'));
const WholeSaleListView = lazy(() => import('./WholeSaleListView.jsx'))
const xlsx = lazy(() => import('../xlsx/DownloadList.js'))
const DataManagementView = lazy(() => import('./DataManagementView.jsx'))
const WholeSaleListAnlyView = lazy(() => import('./WholeSaleListAnlyView.jsx'))

const  view ={
    HomeView,
    ProductAnlyView,
    ProductRankingView,
    UserManagementView,
    UserInfoView,
    WholeSaleListView,
    DataManagementView,
    WholeSaleListAnlyView,
    
    xlsx
};
export default view;
