import  { lazy } from 'react';

// 지연 로딩된 컴포넌트

// layout component -------------------------------
const Nav = lazy(() => import('./layout/Nav.jsx'));
const Header = lazy(() => import('./layout/Header.jsx'));
const HeaderTitle = lazy(() => import('./layout/HeaderTitle.jsx'));

// 공통 component -------------------------------
const CustomSelectBox = lazy(() => import('./CustomSelectBox.jsx'));
const CustomFilterBox = lazy(() => import('./CustomFilterBox.jsx'));
const CustomDateInputBox = lazy(() => import('./CustomDateInputBox.jsx'));
const SearchFiled = lazy(() => import('./SearchFiled.jsx'));
const Pagination = lazy(() => import('./Pagination.jsx'));

const DefaultDiv = lazy(() => import('./defalut/DefaultDiv.jsx'));
const DefaultTitle = lazy(() => import('./defalut/DefaultTitle.jsx'));

// chart component -------------------------------
const CustomLineChart = lazy(() => import('./chart/CustomLineChart.jsx'));
const ChartTitle = lazy(() => import('./chart/ChartTitle.jsx'));


// modal component -------------------------------
const AlertModal = lazy(() => import('./modal/AlertModal.jsx'));
const PlatformModal = lazy(() => import('./modal/PlatformModal.jsx'));
const LoginModal = lazy(() => import('./modal/LoginModal.jsx'));
const PremiumModal = lazy(() => import('./modal/PremiumModal.jsx'));

// 기타 component -------------------------------
const FileDropBox = lazy(() => import('./FileDropBox.jsx'));

// 컴포넌트를 export
export { 
    Header, Nav, HeaderTitle, 
    CustomSelectBox, CustomFilterBox, CustomDateInputBox, Pagination,
    SearchFiled,  
    DefaultDiv, DefaultTitle,
    CustomLineChart, ChartTitle,
    AlertModal, PlatformModal , LoginModal, PremiumModal,
    FileDropBox,
};
