import './App.css';
import React, { Suspense } from 'react';
import Router from './routes/router.js';
function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
        <Router />
      </Suspense>

      // 전 버전
      // <BrowserRouter >
      // {/* <WebLayout> */}
      //   <Router />
      // {/* </WebLayout> */}
      // </BrowserRouter>
  );
}

export default App;
