import { configureStore } from '@reduxjs/toolkit';
import navReducer from './features/navSlice';
import authReducer from './features/authSlice';
import productAnlySlice from './features/productAnlySlice';
import productRankingSlice from './features/productRankingSlice';
import userManagementSlice from './features/userManagementSlice';
import dataManagementSlice from './features/dataManagementSlice';


const store = configureStore({
    reducer: {
        nav: navReducer,
        auth : authReducer,
        productAnly : productAnlySlice,
        productRanking : productRankingSlice,
        userManagement : userManagementSlice,
        dataManagement : dataManagementSlice,
    },
});

export default store;
