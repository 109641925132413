import { createSlice } from "@reduxjs/toolkit";

const dataManagementSlice = createSlice({
    name : 'dataManagement',
    initialState : {
        listSize : 10,
        marketDataList : [{'name': '크림', 'name_en':'Kream', 'weight': 5, key:'123123',dataUrl:'https://naver.com'},{'name': '메롱', 'name_en':'merong', 'weight': 15, key:'432',dataUrl:'https://google.com'},],
        dataList : [
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
            {sendTime : '2023-06-23 19:05:24', marketName : '크림', initDate : 'basic', totalCount : '12332', success : '933' , fail : '12343'},
        ]
    },
    reducers : {},
});

export const {} = dataManagementSlice.actions;
export default dataManagementSlice.reducer;