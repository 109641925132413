import viewPage from '../pages/view/view.js';


const routeList = [
   // 상품분석
   {
      path : '/home',
      isDefaultLayout : true,
      element : <viewPage.HomeView />
   },
   {
      path : '/home/detail',
      isDefaultLayout : true,
      element : <viewPage.ProductAnlyView />
   },
   // 도매리스트 분석
   {
      path : '/wla',
      isDefaultLayout : true,
      element : <viewPage.WholeSaleListAnlyView />
   },
   // 마켓 드랍쉬핑 분석
   {
      path : '/mda',
      isDefaultLayout : true,
      element : <viewPage.HomeView />
   },
   // 상품랭킹
   {
      path : '/pr',
      isDefaultLayout : true,
      element : <viewPage.ProductRankingView />
   },
   // 도매추천
   {
      path : '/wr',
      isDefaultLayout : true,
      element : <viewPage.WholeSaleListView />
   },
   // 기본설정
   {
      path : '/ds',
      isDefaultLayout : true,
      element : <viewPage.UserInfoView />
   },
   // 문의하기
   {
      path : '/inquire',
      isDefaultLayout : true,
      element : <viewPage.HomeView />
   },
   // 회원관리
   {
      path : '/um',
      isDefaultLayout : true,
      element : <viewPage.UserManagementView />
   },
   // 데이터관리
   {
      path : '/dm',
      isDefaultLayout : false,
      element : <viewPage.DataManagementView />
   },
   {
      path : '/xlsx',
      isDefaultLayout : true,
      element : <viewPage.xlsx />
   },
]

export default routeList;
