import { createSlice } from "@reduxjs/toolkit";

const productAnlySlice = createSlice({
    name : 'productAnly',
    initialState :{
        search : '', 
        marketList : ['마켓 전체', '크림','발란','머스트잇','무신사'],
        brandList : ['브랜드 전체','버버리','보테가베네타','발렌티노'],
        marginFilterList : ['직접입력','크림','발란','머스트잇','트랜비','무신사'],

    },
    reducers:{
        getData : (state)=>{} //TODO: api 통신으로 수정
    }
});

export const {getData} = productAnlySlice.actions;
export default productAnlySlice.reducer;