import { createSlice } from '@reduxjs/toolkit';

const typeSlice = createSlice({
    name : 'nav',
    // 초기값 설정
    initialState: {
        type : window.location.href.split('/')[3] ==='old' ? '/old' : '/new', // 선택된 타입 아이템
        selectMenu : window.location.href.split('/')[4], // 선택된 메뉴 아이템
    },
    // 리듀서
    reducers: {
        // 액션
        updateTypeNew : (state) => {state.type ='/new'}, // 신품 타입 선택
        updateTypeOld : (state) => {state.type ='/old'}, // 중고 타입 선택

        setSelectMenu : (state, action) =>{state.selectMenu =  action.payload }, // nav 메뉴 선택
        setInitMenu : (state) =>{state.selectMenu = ''}, // 초기 메뉴 탭 선택
    },
});

export const { updateTypeNew , updateTypeOld, setSelectMenu, setInitMenu} = typeSlice.actions; // 액션 생성자들 내보내기
export default typeSlice.reducer; // 리듀서 내보내기
