import { createSlice } from "@reduxjs/toolkit";

const userManagementSlice = createSlice({
    name :"userManagement",
    initialState: {
        countFilterList : ['10개씩 보기','20개씩 보기','30개씩 보기','50개씩 보기'],
        
    },
    reducers:{

    }
});

export const {} = userManagementSlice.actions;
export default userManagementSlice.reducer;