import { createSlice } from "@reduxjs/toolkit";

const productRankingSlice = createSlice({
    name : 'productRancking',
    initialState : {
        marketList : ['마켓 전체', '크림','발란','머스트잇','무신사'],
        brandList : ['브랜드 전체','버버리','보테가베네타','발렌티노'],
        startDtList : ['2024-08-01','2024-08-02','2024-08-03','2024-08-04','2024-08-05','2024-08-06','2024-08-07'],
        endDtList : ['2024-08-01','2024-08-02','2024-08-03','2024-08-04','2024-08-05','2024-08-06','2024-08-07'],
        saleFiterList : ['판매수량기준','판매금액기준'] //판매수량 필터
    },
    reducers: {},

});

export const {} = productRankingSlice.actions;
export default productRankingSlice.reducer;

