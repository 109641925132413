import {  createBrowserRouter ,RouterProvider} from 'react-router-dom'
import routeList from './routes';
import WebLayout from '../pages/layout/WebLayout.jsx';
import DefaultLayout from '../pages/layout/DefaultLayout.jsx';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const Router = () =>{
  const basenameUrl = useSelector(state => state.nav.type);

  useEffect(() => {
    if (window.location.pathname.indexOf('xlsx') === -1 && !window.location.pathname.startsWith(basenameUrl)) {
      window.location.pathname = basenameUrl + '/home/';
    }
  }, [basenameUrl]);


    const route =  routeList.map((route) => {
      return {
          ...route,
          element: route.isDefaultLayout ? <WebLayout>{route.element}</WebLayout> : <DefaultLayout>{route.element}</DefaultLayout>,
      };
  });

  const router =  createBrowserRouter(route, {basename: basenameUrl} );

  return (<RouterProvider router={router} />)
}

export default Router;

// 전 버전
// const router = () =>{
//   return (
//     <Suspense fallback={<div>Loading...</div>}>
//       <Routes>
//         {
//           routeList.map(item =>{
//             return(
//               <Route key={item.path} path={item.path} element={item.element} />
//             )
//           })
//         }
//         {/* 추가 Route 정의 */}
//       </Routes>
//     </Suspense>
//   );
// }
// export default router;